const configDefault = {
  development: {
    appName: 'mixED.finance Dev',
    appUrl: 'http://localhost:3000',
    configUrl:
      'https://s3.ap-southeast-1.amazonaws.com/config.mixed.finance/network_kjdkjjl32.json',
  },
  production: {
    appName: 'mixED.finance',
    appUrl: 'https://mixed.finance',
    configUrl:
      'https://s3.ap-southeast-1.amazonaws.com/config.mixed.finance/network_kjdkjjl32.json',
    /* configUrl:
      'https://s3.ap-southeast-1.amazonaws.com/config.mixed.finance/network.json', */
  },
}
const config = {
  ...configDefault[process.env.NODE_ENV],
  // ...configDefault['development'],
  version: '0.0.2',
}
export default config
