import React, { lazy } from 'react'

const Orders = lazy(() => import('../containers/Orders'))

const mainRoutes = [
  {
    path: '/',
    exact: true,
    element: <Orders />,
  },
  {
    path: '/tokens',
    exact: true,
    element: <Orders />,
  },
  {
    path: '/tokens/myOrders',
    exact: true,
    element: <Orders />,
  },
  {
    path: '/nfts',
    exact: true,
    element: <Orders />,
  },
  {
    path: '/nfts/myOrders',
    exact: true,
    element: <Orders />,
  },
  /* {
    path: '/:type',
    exact: true,
    element: <Orders />,
  }, */
]

export { mainRoutes }
